// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { store } from 'store';
import { updateJobToJd, addNewJobDescription, setModelForJdCreation } from "./job-description";
import jobs from "./jobs_data.json";
import moment from "moment";
//import jobListDemo from './get_joblisting_for_client_demo.json';
//import  jobDetailsDemo from './get_job_details_from_jobid_demo.json';

// ----------------------------------------------------------------------

const initialState = {
    jobs: [],
    jobsBkp: [],
    selectedJobs: [],
    selectedJob: null,
    jobDialogViewType: "",
    sortKey: "",
    experienceRanges: ["0-1 Years", "1-2 Years", "1-3 Years", "2-5 Years"]
};

const slice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        // GET USERS
        setJobsInit(state, action) {
            state.jobs = action.payload;
            state.jobsBkp = action.payload;
        },
        setJobs(state, action) {
            state.jobs = action.payload;
        },
        setSelectedJobs(state, action) {
            state.selectedJobs = action.payload;
        },
        setSelectedJob(state, action) {
            state.selectedJob = action.payload;
        },
        openModelForLinkJDCandidateUpload(state, action) {
            state.selectedJob = action.payload.item;
            state.jobDialogViewType = action.payload.type;
        },
        setJobsBkp(state, action) {
            state.jobsBkp = action.payload;
        },
        setSortKey(state, action) {
            state.sortKey = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function getJobs() {
    return async () => {
        try {
            const formData = new FormData();
            formData.append("client", "DataPhi");
            formData.append("account", "DataPhi");
            const response = await axios.post("/get_joblisting_for_client", formData);
            //const response = {data:jobListDemo, status:200};
            if(response && response.data && response.data.job_list && response.status === 200){
                const key = "created_date";
                dispatch(slice.actions.setSortKey(key));
                dispatch(slice.actions.setJobsInit(sortJobs(key, response.data.job_list, true)));
            }
        } catch (error) {
            console.log("getJobs error", error);
        }
    };
}

export function setJobs(responsePayload) {
    return async () => {
        try {
            dispatch(slice.actions.setJobs(responsePayload));
        } catch (error) {
            console.log("setJobs error", error);
        }
    };
}

const getJobsList = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobs.jobs));
}

const getJobsListBkp = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobs.jobsBkp));
}


const getSelectedJobsList = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobs.selectedJobs));
}

const getSelectedJob = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobs.selectedJob));
}

export function candidateUploadForJob(jobId, count) {
    try {
        let jobs = getJobsList();
        jobs.forEach((job) => {
            if (job && job.requirement_id === jobId) {
                job.sourced = job.sourced + count;
            }
        })
        let jobsBkp = getJobsListBkp();
        jobsBkp.forEach((job) => {
            if (job && job.requirement_id === jobId) {
                job.sourced = job.sourced + count;
            }
        })
        dispatch(slice.actions.setJobs(jobs));
        dispatch(slice.actions.setJobsBkp(jobsBkp));
    } catch (error) {

    }
}

export function linkJDForJob(jdObj) {
    return async () => {
        try {
            let jobObj = getSelectedJob();
            let jobs = getJobsList();
            jobs.forEach((job) => {
                if (job && jobObj && job.id === jobObj.id) {
                    job.linkedJD = jdObj.id;
                    job.year_of_exp_range = jdObj.caption;
                }
            })
            let jobsBkp = getJobsListBkp();
            jobsBkp.forEach((job) => {
                if (job && jobObj && job.id === jobObj.id) {
                    job.linkedJD = jdObj.id;
                    job.year_of_exp_range = jdObj.caption;
                }
            })
            dispatch(slice.actions.setJobs(jobs));
            dispatch(slice.actions.setJobsBkp(jobsBkp));
            if(jdObj && jdObj.id){
                dispatch(updateJobToJd(jdObj.id));
            }
            dispatch(setModelForLinkJDCandidateUpload({ item: null, type: "" }));
            dispatch(setModelForJdCreation({ item: null, type: false }));
        } catch (error) {
            console.log("linkJDForJob error", error)
        }
    }
}

const crateJobObj = (item) => {
    return {
        "agencyName": "LTI",
        "requirement_id": "R10023".concat(Math.floor(Math.random() * (999 - 100 + 1) + 100)),
        "job_id": "LT10342",
        "business_unit": item["Business Unit"],
        "job_status": "Open",
        "job_role": item["Job Role"],
        "year_of_exp_range": item["Years of Experience - Range"],
        "required_skills": "Python, Flask, PostGres, Docker",
        "job_location": {
            "country_code": "IN",
            "state_code": "MS",
            "city": item["Job Location"]
        },
        "hiring_manager": item["Hiring Manager"],
        "project_id": item["Project ID"],
        "urgency": item["Urgency"],
        "recruiter": "Bruce Wayne",
        "created_date": new Date().toISOString(),
        "updated_date": new Date().toISOString(),
        "sourced": 0,
        "recommended": 0,
        "assessed": 0,
        "offerd": 0,
        "project_short": "DR",
        "days_in_sourcing": "0",
        "linkedJD": null
    }
}

export function addJobs(newJobs) {
    try {
        let jobs = getJobsList();
        newJobs.forEach((job) => {
            if (job && !job.invalidRow) {
                jobs.push(crateJobObj(job))
            }
        });
        let jobsBkp = getJobsListBkp();
        jobsBkp.forEach((job) => {
            if (job && !job.invalidRow) {
                jobs.push(crateJobObj(job))
            }
        });
        dispatch(slice.actions.setJobs(jobs));
        dispatch(slice.actions.setJobsBkp(jobsBkp));
    } catch (error) {
        console.log("error", error);
    }
}

export function selectJob(item) {
    return async () => {
        try {
            
            let selectedJobs = getSelectedJobsList();
            let selectedIndex = selectedJobs.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (selectedIndex === -1) {
                selectedJobs.push(item);
                dispatch(slice.actions.setSelectedJobs(selectedJobs));
            }

            let jobs = getJobsList();
            let index = jobs.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (index > -1) {
                let jobItem = JSON.parse(JSON.stringify(jobs[index]));
                if (!jobItem["selected"]) {
                    jobItem["selected"] = true;
                    jobs[index] = jobItem;
                    dispatch(slice.actions.setJobs(jobs));
                }
            }
            let jobsBkp = getJobsListBkp();
            let indexBkp = jobsBkp.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (indexBkp > -1) {
                let jobItem = JSON.parse(JSON.stringify(jobsBkp[indexBkp]));
                if (!jobItem["selected"]) {
                    jobItem["selected"] = true;
                    jobsBkp[indexBkp] = jobItem;
                    dispatch(slice.actions.setJobsBkp(jobsBkp));
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }
}

export function deSelectJob(item) {
    return async () => {
        try {
            let selectedJobs = getSelectedJobsList();
            let selectedJobIndex = selectedJobs.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (selectedJobIndex > -1) {
                selectedJobs.splice(selectedJobIndex, 1);
                dispatch(slice.actions.setSelectedJobs(selectedJobs));
            }

            let jobs = getJobsList();
            let index = jobs.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (index > -1) {
                let jobItem = JSON.parse(JSON.stringify(jobs[index]));
                jobItem["selected"] = false;
                jobs[index] = jobItem;
                dispatch(slice.actions.setJobs(jobs));
            }

            let jobsBkp = getJobsListBkp();
            let indexBkp = jobsBkp.findIndex((job) => (job.requirement_id === item.requirement_id));
            if (indexBkp > -1) {
                let jobItem = JSON.parse(JSON.stringify(jobsBkp[indexBkp]));
                jobItem["selected"] = false;
                jobsBkp[indexBkp] = jobItem;
                dispatch(slice.actions.setJobsBkp(jobsBkp));
            }
        } catch (error) {
            console.log("error", error);
        }
    }

}

export function setSelectedJob(job_details) {
    return async () => {
        try {
            dispatch(slice.actions.setSelectedJob(job_details));
        } catch (error) {
            console.log("setSelectedJob error", error);
        }
    };
}

export function fetchSelectedJob(id) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append("jobid", id);
            const response = await axios.post("/get_job_details_from_jobid", formData);
            //const response ={data:jobDetailsDemo, status:200};
            if(response && response.data && response.data.job_details && response.status === 200){
                dispatch(slice.actions.setSelectedJob(response.data.job_details));
            }
        } catch (error) {
            console.log("setSelectedJob error", error);
        }
    };
}

export function setModelForLinkJDCandidateUpload(payload) {
    return async () => {
        try {
            dispatch(slice.actions.openModelForLinkJDCandidateUpload(payload));
        } catch (error) {
            console.log("error", error);
        }
    }
}

export function addAndLinkJdToJob(obj) {
    return async () => {
        try {
            // const jObj = {
            //     "Job Role": obj.payload.label,
            //     "Years of Experience - Range": obj.payload.caption
            // }
            // if(obj.isNewJobAddRequire){
            //     addJobs([jObj]);
            // }
            dispatch(addNewJobDescription(obj.payload));
            dispatch(linkJDForJob(obj.payload));
        } catch (error) {
            console.log("addAndLinkJdToJob error", error);
        }
    }
}


const sortJobs = (key, list, isDate) => {
    let newList = JSON.parse(JSON.stringify(list));
    if (key) {
        newList.sort((a, b) => {
            if(isDate){
                return moment(b[key]).utc() > moment(a[key]).utc() ? 1 : moment(b[key]).utc() < moment(a[key]).utc() ? -1 : 0;
            }else{
                return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
            }
        })
    }
    return newList;
}

export function setSortBy(payload) {
    return async () => {
        try {
            dispatch(slice.actions.setSortKey(payload.value));

            let jobs = sortJobs(payload.value, getJobsList(), payload.isDate);
            dispatch(slice.actions.setJobs(jobs));

            let jobsBkp = sortJobs(payload.value, getJobsListBkp(), payload.isDate);
            dispatch(slice.actions.setJobsBkp(jobsBkp));

        } catch (error) {
            console.log("error", error);
        }
    }
}
