import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const JobDescriptionCreate = Loadable(lazy(() => import('views/job-description-create')));
const CandidateList = Loadable(lazy(() => import('views/candidate-list')));
const JobList = Loadable(lazy(() => import('views/job-list')));


const JobDescriptionEdit = Loadable(lazy(() => import('views/job-description-edit')));
const CandidateDetails = Loadable(lazy(() => import('views/candidate-details')));
const JobDetails = Loadable(lazy(() => import('views/job-details')));

const JobDescriptionUpload = Loadable(lazy(() => import('views/job-description-upload')));
const CandidateUpload = Loadable(lazy(() => import('views/candidate-upload')));
const JobUpload = Loadable(lazy(() => import('views/job-upload')));

const AdminClientCreate = Loadable(lazy(() => import('views/admin/client')));
const AdminAccountCreate = Loadable(lazy(() => import('views/admin/account')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/app',
            element: <Dashboard />
        },
        {
            path: '/app/candidate',
            children: [
                {
                    path: '/app/candidate/',
                    element: <CandidateList />
                },
                {
                    path: '/app/candidate/:id',
                    element: <CandidateDetails />
                },
                {
                    path: '/app/candidate/upload',
                    element: <CandidateUpload />,
                    exact: true
                }
            ]
        },
        {
            path: '/app/jobs',
            children: [
                {
                    path: '/app/jobs',
                    element: <JobList />,
                },

                {
                    path: '/app/jobs/:id',
                    element: <JobDetails />
                },
                {
                    path: '/app/jobs/upload',
                    element: <JobUpload />
                },
                {
                    path: '/app/jobs/job-description',
                    element: <JobDescriptionCreate />,
                },
                {
                    path: '/app/jobs/job-description/edit',
                    element: <JobDescriptionEdit />
                },
                {
                    path: '/app/jobs/job-description/upload',
                    element: <JobDescriptionUpload />
                }
            ]
        },
        // {
        //     path: '/app/job-description',
        //     children: [
                
        //     ]
        // },
        {
            path: '/app/zeepledb',
            children: [
                {
                    path: '/app/zeepledb/',
                    element: <CandidateList />
                }
            ]
        },
        {
            path: 'app/insight',
            element: <Dashboard />


        },
        {
            path: 'app/engagement',
            element: <Dashboard />

        },
        {
            path: '/app/admin',
            children: [
                {
                    path: '/app/admin/client',
                    element: <AdminClientCreate />,
                },
                {
                    path: '/app/admin/account',
                    element: <AdminAccountCreate />,
                }
            ]
        },
    ]
};

export default MainRoutes;
