// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard, IconDeviceAnalytics, IconBasket, IconClipboardList, IconPictureInPicture,
    IconUsers, IconHeartRateMonitor, IconBoxMultiple, IconUserPlus, IconDatabase
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBasket,
    IconClipboardList,
    IconPictureInPicture,
    IconUsers,
    IconHeartRateMonitor,
    IconBoxMultiple,
    IconUserPlus,
    IconDatabase
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'app',
    title: "",
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/app',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        // {
        //     id: 'Zeeple Find',
        //     title: <FormattedMessage id="Zeeple Find" />,
        //     type: 'item',
        //     url: '/app/job-description',
        //     icon: icons.IconClipboardList,
        //     breadcrumbs: true
        // },
        {
            id: 'jobs',
            title: <FormattedMessage id="Jobs" />,
            type: 'item',
            url: '/app/jobs',
            icon: icons.IconClipboardList,
            breadcrumbs: true
        },
        {
            id: 'Zeeple DB',
            title: <FormattedMessage id="Zeeple DB" />,
            type: 'item',
            url: '/app/zeepledb',
            icon: icons.IconUsers,
            breadcrumbs: true
        },
        {
            id: 'Insight',
            title: <FormattedMessage id="Insight" />,
            type: 'item',
            url: '/app/insight',
            icon: icons.IconHeartRateMonitor,
            breadcrumbs: true
        },
        {
            id: 'Engagement',
            title: <FormattedMessage id="Engagement" />,
            type: 'item',
            url: '/app/engagement',
            icon: icons.IconBoxMultiple,
            breadcrumbs: true
        },
        {
            id: 'Ref Check',
            title: <FormattedMessage id="Ref Check" />,
            type: 'item',
            url: '/app/candidate',
            icon: icons.IconUserPlus,
            breadcrumbs: true
        },
        {
            id: 'Admin',
            title: <FormattedMessage id="Admin" />,
            type: 'collapse',
            url: '/app/admin',
            icon: icons.IconDatabase,
            children: [
                {
                    id: 'Client',
                    title: <FormattedMessage id="Client" />,
                    type: 'item',
                    url: '/app/admin/client',
                    //icon: icons.IconDatabase,
                    breadcrumbs: false,
                },
                {
                    id: 'Account',
                    title: <FormattedMessage id="Account" />,
                    type: 'item',
                    url: '/app/admin/account',
                    //icon: icons.IconDatabase,
                    breadcrumbs: false,
                }
            ]
        }
        // {
        //     id: 'Job Description',
        //     title: <FormattedMessage id="Job Description" />,
        //     type: 'item',
        //     url: '/app/job-description',
        //     icon: icons.IconPictureInPicture,
        //     breadcrumbs: false
        // },

    ]
};

export default dashboard;
