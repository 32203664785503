import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import { BASE_PATH } from 'config';
import { gridSpacing } from 'store/constant';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const linkSX = {
    display: 'flex',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: "14px"
};

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ card, divider, icon, icons, maxItems, navigation, rightAlign, separator, title, titleBottom, ...others }) => {
    const theme = useTheme();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main
    };

    // item separator
    const separatorIcon =  <ChevronRightIcon stroke={1.5} size="1rem" />;

    // collapse item
    // if (main && main.type === 'collapse') {
    //     CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
    //     mainContent = (
    //         <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
    //             {icons && <CollapseIcon style={iconStyle} />}
    //             {main.title}
    //         </Typography>
    //     );
    // }

    // items

    const getItem = (item) => {
        const itemTitle = item.title;
        const ItemIcon = item.icon ? item.icon : <div></div>;
        return (
            <div key={`bread-item-${item.title}`}>
                    {item.type === "link" ?
                        <Typography component={Link} to={item.link} color="primary" variant="subtitle1" sx={linkSX}>
                            {item.icon && <ItemIcon style={iconStyle} />}
                            {itemTitle}
                        </Typography> :
                        <Typography
                            variant="subtitle1"
                            sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                alignContent: 'center',
                                alignItems: 'center',
                                fontWeight: 400,
                                color: 'grey.500',
                                fontSize: "12px"
                            }}
                        >
                            {/* {ItemIcon && <ItemIcon style={iconStyle} />} */}
                            {itemTitle}
                        </Typography>}
                </div>
        )
    }

    return (
        <Card
            sx={{
                marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
                border: card === false ? 'none' : '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                background: card === false ? 'transparent' : theme.palette.background.default
            }}
            {...others}
        >
            <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                <Grid
                    container
                    direction={rightAlign ? 'row' : 'column'}
                    justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                    alignItems={rightAlign ? 'center' : 'flex-start'}
                    spacing={1}
                >
                    {title && !titleBottom && (
                        <Grid item>
                            <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <MuiBreadcrumbs
                            sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                            aria-label="breadcrumb"
                            maxItems={maxItems || 8}
                            separator={separatorIcon}
                        >
                            {
                                navigation && navigation.map((menu, i) => (
                                    getItem(menu, i)
                                ))
                            }
                        </MuiBreadcrumbs>
                    </Grid>
                    {title && titleBottom && (
                        <Grid item>
                            <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                {title}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Card>

    );
};

Breadcrumbs.propTypes = {
    card: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.bool,
    icons: PropTypes.bool,
    maxItems: PropTypes.number,
    navigation: PropTypes.array,
    rightAlign: PropTypes.bool,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.string,
    titleBottom: PropTypes.bool
};

export default Breadcrumbs;
