// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
//import candidateListDemo from './get_candidatelist_by_jobid_demo.json';

// ----------------------------------------------------------------------

const initialState = {
    candidates: [],
    candidatesForUploads: null
};

const slice = createSlice({
    name: 'candidates',
    initialState,
    reducers: {
        // GET USERS
        setCandidates(state, action) {
            state.candidates = action.payload;
        },
        setCandidatesForUpload(state, action) {
            state.candidatesForUploads = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getCandidates(filterObj) {
    return async () => {
        try {
            const formData = new FormData();
            for(let key in filterObj){
                formData.append(key, filterObj[key]);
            }
            // const response = await axios.post('/get_candidatelist_by_jobid', formData);
            const response = await axios.post('/get_candidatelist_by_jobid', formData);
            //const response ={data:candidateListDemo, status:200};
            if(response && response.data && response.data.candidate_list && response.status === 200){
                dispatch(slice.actions.setCandidates(response.data.candidate_list));
            }
        } catch (error) {
            console.log("getCandidates error", error);
        }
    };
}

export function setCandidates(responsePayload) {
    return async () => {
        try {
            dispatch(slice.actions.setCandidates(responsePayload));
        } catch (error) {

        }
    };
}

export function setCandidatesForUpload(responsePayload) {
    return async () => {
        try {
            dispatch(slice.actions.setCandidatesForUpload(responsePayload));
        } catch (error) {

        }
    };
}
