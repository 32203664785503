// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';
import engagmentFlow from './get_engagementFlows.json'

// ----------------------------------------------------------------------

const initialState = {
    engagementFlows: [],
    error: null
};

const slice = createSlice({
    name: 'engagementFlows',
    initialState,
    reducers: {
        setEngagementFlows(state, action) {
            state.engagementFlows = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Thunks
export function getEngagementFlows(job_id, client_id) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('job_id', job_id);
            formData.append('client_id', client_id);

            //const response = await axios.post('http://localhost:5015/getEngagementFlows', formData);

            // const response = await axios.post('http://localhost:5015/getEngagementFlows',
            //     {
            //         job_id:'81181144',
            //         client_id:'3'
            //     }
            // );

            // const response = await axios.post('http://localhost:5015/getEngagementFlows',
            //     {
            //         job_id:formData.get('job_id'),
            //         client_id:formData.get('client_id')
            //     }
            // );

            const response = await axios.post('/getEngagementFlows', formData);
            //const response ={data:engagmentFlow, status:200};
            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.setEngagementFlows(response.data));
            } else {
                dispatch(slice.actions.setError('Failed to fetch engagement flows'));
            }
        } catch (error) {
            console.log("getEngagementFlows error", error);
            dispatch(slice.actions.setError('An error occurred while fetching engagement flows'));
        }
    };
}

export function setEngagementFlows(responsePayload) {
    return async () => {
        try {
            dispatch(slice.actions.setEngagementFlows(responsePayload));
        } catch (error) {
            console.log("setEngagementFlows error", error);
            dispatch(slice.actions.setError('An error occurred while setting engagement flows'));
        }
    };
}
