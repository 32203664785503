// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { store } from 'store';

// ----------------------------------------------------------------------

const initialState = {
    jobDescriptions: [],
    jobDescriptionsBkp: [],
    selectedJD: null,
    jbDialogViewType: false,
    createJD: {}
};

const slice = createSlice({
    name: 'jobDescription',
    initialState,
    reducers: {
        // GET USERS
        setJobDescriptions(state, action) {
            state.jobDescriptions = action.payload;
        },
        setJobDescriptionsBkp(state, action) {
            state.jobDescriptionsBkp = action.payload;
        },
        setSelectedJD(state, action) {
            state.selectedJD = action.payload;
        },
        setModelForLinkJDCreation(state, action) {
            state.selectedJob = action.payload.item;
            state.jbDialogViewType = action.payload.type;
        },
        setCreateJDObject(state, action) {
            state.createJD = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getJobDescriptions(requestPayload) {
    return async () => {
        try {
            //const response = await axios.get('/api/chat/users');
            dispatch(slice.actions.setJobDescriptions(requestPayload));
            dispatch(slice.actions.setJobDescriptionsBkp(requestPayload));
        } catch (error) {

        }
    };
}

export function setJobDescriptions(responsePayload) {
    return async () => {
        try {
            dispatch(slice.actions.setJobDescriptions(responsePayload));
            dispatch(slice.actions.setJobDescriptionsBkp(responsePayload));
        } catch (error) {

        }
    };

}


const getJobDescriptionListBkp = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobDescription.jobDescriptionsBkp));
}

const getJobDescriptionList = () => {
    const reduxStore = store.getState();
    return JSON.parse(JSON.stringify(reduxStore.jobDescription.jobDescriptions));
}

export function searchForJobDescripton(searchString) {
    try {
        let jds = getJobDescriptionListBkp();
        if (searchString) {
            let result = jds.filter((jd) => {
                return jd.label.toLowerCase().indexOf(searchString.toLowerCase()) > -1
            });
            dispatch(slice.actions.setJobDescriptions(result));
        } else {
            dispatch(slice.actions.setJobDescriptions(jds));
        }
    } catch (error) {

    }
}

export function setSelectedJD(jdObj) {
    return async () => {
        try {
            dispatch(slice.actions.setSelectedJD(jdObj));
        } catch (error) {

        }
    };
}

export function updateJobToJd(jobId) {
    return async () => {
        try {
            if (jobId) {
                let jds = JSON.parse(JSON.stringify(getJobDescriptionList()));
                let index = jds.findIndex((jdObj) => (jdObj && jobId && jdObj.id === jobId))
                if (index > -1) {
                    if (!jds[index].jobs_linked) {
                        jds[index].jobs_linked = [];
                    }
                    if (!jds[index].jobs_linked.includes(jobId)) {
                        jds[index].jobs_linked.push(jobId);
                    }
                }
                let jdsBkp = JSON.parse(JSON.stringify(getJobDescriptionListBkp()));
                let indexBkp = jdsBkp.findIndex((jdObj) => (jdObj && jobId && jdObj.id === jobId));
                if (indexBkp > -1) {
                    if (!jdsBkp[indexBkp].jobs_linked) {
                        jdsBkp[indexBkp].jobs_linked = [];
                    }
                    if (!jdsBkp[indexBkp].jobs_linked.includes(jobId)) {
                        jdsBkp[indexBkp].jobs_linked.push(jobId);
                    }
                }
                dispatch(slice.actions.setJobDescriptionsBkp(jdsBkp));
                dispatch(slice.actions.setJobDescriptions(jds));
                dispatch(slice.actions.setSelectedJD(null));
            }
        } catch (error) {
            console.log("error", error);
        }
    };
}

export function addNewJobDescription(payload) {
    return async () => {
        try {
            let jds = JSON.parse(JSON.stringify(getJobDescriptionList()));
            let index = jds.findIndex((jdObj) => (jdObj && payload && jdObj.id === payload.id));
            if (index === -1) {
                jds.push(payload);
                dispatch(slice.actions.setJobDescriptions(jds));
            }
            let jdsBkp = JSON.parse(JSON.stringify(getJobDescriptionListBkp()));
            let indexBkp = jdsBkp.findIndex((jdObj) => (jdObj && payload && jdObj.id === payload.id));
            if (indexBkp === -1) {
                jdsBkp.push(payload);
                dispatch(slice.actions.setJobDescriptionsBkp(jdsBkp));
            }
        } catch (error) {
            console.log("error addNewJobDescription", error);
        }
    };
}

export function setModelForJdCreation(requestPayload) {
    return async () => {
        try {
            dispatch(slice.actions.setModelForLinkJDCreation(requestPayload));
        } catch (error) {
            console.log("error", error);
        }
    }
}

export function setJdCreateUpdateData(requestPayload) {
    return async () => {
        try {
            let obj = {};
            if (requestPayload) {
                const locationParse = requestPayload.job_location && 
                typeof requestPayload.job_location === "string" ?
                requestPayload.job_location.trim().split(","): requestPayload.job_location;
                const location = locationParse ? locationParse : [];

                obj = {
                    "online_unique_number": requestPayload.online_unique_number || null,
                    "client": requestPayload.client || "DataPhi",
                    "account": requestPayload.account || "DataPhi",
                    "number_of_positions": requestPayload.number_of_positions,
                    "job_mode": requestPayload.job_mode,
                    "expected_salary_start_range": requestPayload.expected_salary_start_range ? requestPayload.expected_salary_start_range : 0,
                    "expected_salary_end_range": requestPayload.expected_salary_end_range ? requestPayload.expected_salary_end_range : 0,
                    "jdData": requestPayload.jdData || "",
                    
                    "job_designation": requestPayload.job_designation || "",
                    "job_location": location,
                    "notice_period": requestPayload.notice_period || null,
                    "minimum_years_of_experience_required":requestPayload.minimum_years_of_experience_required || null,
                    "maximum_years_of_experience_required":requestPayload.maximum_years_of_experience_required || null,
                    "key_skill": requestPayload.key_skill || "",
                    
                    "education_qualification": requestPayload.education_qualification || "",
                    "search_from_previous_employer_exclude": requestPayload.search_from_previous_employer_exclude || "",
                    "search_from_previous_employer_exclude": requestPayload.search_from_previous_employer_exclude || "",
                    "domain": requestPayload.domain || "",
                    "any_other_job_role_specificNotes": requestPayload.any_other_job_role_specificNotes || "",
                    "number_of_resumes_needed_per_position": requestPayload.number_of_resumes_needed_per_position ||10,
                    "moveTo": requestPayload.moveTo || 0,
                    "isJobEdit": requestPayload.isJobEdit || false,
                };
                
            }
            dispatch(slice.actions.setCreateJDObject(obj));
        } catch (error) {
            console.log("error", error);
        }
    }
}

